<template>
  <v-card color="white">
    <crud-header
      :title="title"
      type="Annotation"
      store="annotation"
      deletable
      @delete-success="$router.push({ name: 'annotations' })"
    >
      <v-menu
        v-if="hasFiles"
        slot="toolbarActions"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        left
        offset-x
      >
        <v-btn
          slot="activator"
          :disabled="isDownloading"
          :loading="isDownloading"
          icon
        >
          <v-icon>far fa-fw fa-file-download</v-icon>
        </v-btn>

        <v-list>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon>far fa-fw fa-file</v-icon>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>Attachments</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>

        <v-divider />

        <v-card>
          <v-list>
            <v-list-tile v-for="(file, index) in files" :key="index">
              <v-list-tile-action>
                <v-btn
                  slot="activator"
                  icon
                  @click="downloadFile(file.id, index)"
                >
                  <v-icon>far fa-fw fa-download</v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Download</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  file.fileName
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-menu>
    </crud-header>
    <annotation />
  </v-card>
</template>

<script type="text/babel">
import annotationModule from "@/store/modules/annotation";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("annotation");
import { mapDetailViewLifecycleMethods } from "../helpers";
import api from "@/api";
import Annotation from "@/components/annotation/Annotation";

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "annotation",
  annotationModule
);

const downloadFileCall = api.annotation.downloadFile;

export default {
  name: "annotation-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Annotation,
    CrudHeader
  },
  data: () => {
    return {
      downloadCall: downloadFileCall,
      menu: false,
      isDownloading: false
    };
  },
  computed: {
    ...mapGetters(["title", "id", "files"]),
    hasFiles() {
      return this.files.length > 0;
    }
  },
  methods: {
    async downloadFile(fileId) {
      this.menu = false;
      this.isDownloading = true;
      await this.downloadCall(fileId);
      this.isDownloading = false;
    }
  },
  ...detailViewLifecycleMethods
};
</script>
