<template>
  <v-container fluid>
    <v-text-field v-model="title" label="Title" :counter="255" />
    <b-date-picker v-model="date" />
    <v-autocomplete-annotation-type
      v-model="type"
      @data-loaded="typeLoaded"
      :data-vv-rules="'required'"
      :data-vv-name="`type`"
      :error-messages="errors.collect(`type`)"
    />
    <v-autocomplete-annotation-sub-type
      v-if="!isTypeNull"
      v-model="subTypes"
      :type="type"
      :reload-sub-type="reloadSubType"
      item-text="title"
      multiple
      @set-reload-sub-type="setReloadSubType"
    />
    <v-autocomplete-article
      v-if="type === 3"
      v-model="articles"
      multiple
      item-text="path"
      @set-articles="setSelectedArticles"
    />
    <v-autocomplete-website
      v-if="type === 2"
      v-model="websites"
      multiple
      item-text="host"
    />
    <v-checkbox
      v-if="type === 3"
      v-model="addToWebsite"
      label="Add annotation to website"
    ></v-checkbox>
    <v-upload-image
      v-model="file"
      preview
      label="Annotation picture"
      placeholder="Click to upload a new annotation picture"
      :error-messages="errors.collect('files')"
    />
    <v-layout row>
      <v-flex v-for="(image, index) in images.slice(0, 4)" :key="index" xs3>
        <v-card>
          <div class="img-container">
            <a :href="`http://cdn.buldit.be/${image.filePath}`" target="_blank">
              <v-img
                :src="`http://cdn.buldit.be/${image.filePath}`"
                aspect-ratio="1"
              />
            </a>
            <v-btn
              fab
              dark
              small
              color="primary"
              class="img-button"
              @click="deleteImage(image.id)"
              ><v-icon dark>far fa-trash-alt</v-icon></v-btn
            >
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <div style="min-height: 200px;">
      <quill-editor
        ref="textEditor"
        v-model="body"
        :options="editorOption"
        style="height: 100px !important;"
      />
    </div>
    <annotation-img-dialog
      :show-dialog="showDialog"
      :carousel-index="imageIndex"
      :images="images"
      @close="showDialog = false"
    />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteAnnotationType from "../annotation-type/VAutocompleteAnnotationType";
import VAutocompleteAnnotationSubType from "../annotation-sub-type/VAutocompleteAnnotationSubType";
const { mapComputed } = createNamespacedHelpers("annotation");
import { isNil } from "lodash";
import VAutocompleteArticle from "../article/VAutocompleteArticle";
import VAutocompleteWebsite from "../website/VAutocompleteWebsite";
import AnnotationImgDialog from "@/components/annotation/AnnotationImgDialog";
import VUploadImage from "@/components/generic/input/VUploadImage";
import BDatePicker from "@/components/generic/input/dates/BDatePicker";

export default {
  name: "annotation",
  components: {
    BDatePicker,
    VUploadImage,
    AnnotationImgDialog,
    VAutocompleteWebsite,
    VAutocompleteArticle,
    VAutocompleteAnnotationSubType,
    VAutocompleteAnnotationType
  },
  inject: ["$validator"],
  props: {
    websiteIds: {
      type: Array,
      default() {
        return [];
      }
    },
    articleIds: {
      type: Array,
      default() {
        return [];
      }
    },
    subjectIds: {
      type: Array,
      default() {
        return [];
      }
    },
    keywordIds: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: () => ({
    menu: false,
    showDialog: false,
    imageIndex: null,
    selectedArticles: [],
    selectedArticlesWebsiteIds: [],
    uniqueWebsiteIds: [],
    addToWebsite: false,
    editorOption: {
      theme: "snow",
      modules: {
        markdownShortcuts: {},
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
          ["link"]
        ]
      }
    },
    isTypeLoaded: false,
    reloadSubType: false
  }),
  computed: {
    ...mapComputed([
      "id",
      "title",
      "type",
      "subTypes",
      "date",
      "body",
      "keywords",
      "subjects",
      "articles",
      "websites",
      "files",
      "file"
    ]),
    isTypeNull() {
      return isNil(this.type);
    },
    images() {
      if (Array.isArray(this.files)) {
        return this.files.filter(e => {
          const extension = e.fileName ? e.fileName.split(".").pop() : null;
          return (
            extension === "jpg" || extension === "jpeg" || extension === "png"
          );
        });
      } else {
        return [];
      }
    }
  },
  watch: {
    type() {
      this.reloadSubType = true;
    },
    addToWebsite(newVal) {
      if (newVal) {
        this.$store.commit("annotation/setWebsites", this.uniqueWebsiteIds);
      } else {
        this.$store.commit("annotation/setWebsites", []);
      }
    }
  },
  mounted() {},
  methods: {
    typeLoaded() {
      this.isTypeLoaded = true;
    },
    setReloadSubType(val) {
      this.reloadSubType = val;
    },
    openDialog(index) {
      this.showDialog = true;
      this.imageIndex = index;
    },
    setSelectedArticles(val) {
      this.selectedArticles = val;
      this.getSelectedArticlesWebsites();
    },
    getSelectedArticlesWebsites() {
      this.selectedArticles.forEach(e => {
        if (!isNil(e)) {
          this.selectedArticlesWebsiteIds.push(e.website.id);
        }
      });
      this.uniqueWebsiteIds = [...new Set(this.selectedArticlesWebsiteIds)];
      if (this.websites > 0) {
        this.addToWebsite = true;
      }
    },
    deleteImage(fileId) {
      this.$store.dispatch("annotation/deleteImage", {
        annotationId: this.id,
        fileId: fileId
      });
    }
  }
};
</script>
<style scoped>
@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg3-custom {
    width: 33.333%;
    max-width: 33.333%;
    flex-basis: 33.333%;
  }
}
p {
  margin-bottom: 5px;
}
.img-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.img-container img {
  width: 100%;
  height: auto;
}

.img-button {
  position: absolute;
  top: 90%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  text-align: center;
}
</style>
