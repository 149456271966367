<template>
  <v-card color="white">
    <crud-header
      :title="title"
      type="Annotation"
      store="annotation"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'annotations.show',
            params: { annotationId: id }
          })
      "
    />
    <annotation />
  </v-card>
</template>

<script type="text/babel">
import annotationModule from "@/store/modules/annotation";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("annotation");
import { mapCreateViewLifecycleMethods } from "../helpers";
import Annotation from "../../components/annotation/Annotation";

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "annotation",
  annotationModule
);

export default {
  name: "annotation-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Annotation,
    CrudHeader
  },
  computed: {
    ...mapGetters(["title"])
  },
  ...createViewLifecycleMethods
};
</script>
