<template>
  <table-overview
    title="Annotations"
    store="annotations"
    :headers="[
      { text: 'title', value: 'title' },
      { text: 'Types', value: 'types', sortable: false },
      { text: 'Subtypes', value: 'subtypes', sortable: false },
      { text: 'Author', value: 'author', sortable: false },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'annotations_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <annotation-table-row
      slot="table-row"
      slot-scope="{ item }"
      :annotation-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import annotationsModule from "@/store/modules/annotations";
import AnnotationTableRow from "../../components/annotation/AnnotationTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "annotations",
  annotationsModule
);

export default {
  name: "annotations-view",
  components: {
    AnnotationTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "annotations.create" },
        rolesOrPermissions: "annotations_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
