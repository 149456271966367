<template>
  <tr>
    <td>
      <v-icon :style="`color: ${annotation.color}`" class="mr-3">
        {{ annotation.color ? "brightness_1" : "panorama_fish_eye" }} </v-icon
      >{{ annotation.title }}
    </td>
    <td>
      {{ typeName }}
    </td>
    <td>
      <v-chip v-for="(subType, index) in subTypes" :key="index" label small>
        {{ subType.title }}
      </v-chip>
    </td>
    <td>{{ annotation.author.firstName }} {{ annotation.author.lastName }}</td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'annotations.show',
          params: { annotationId: annotation.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import { isNil } from "lodash";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapAnnotationGetters,
  mapState: mapAnnotationState
} = createNamespacedHelpers("annotations");

export default {
  name: "annotation-table-row",
  props: {
    annotationId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapAnnotationGetters([FIND_BY_ID]),
    ...mapAnnotationState(["annotationType", "annotationSubType"]),
    annotation() {
      return this[FIND_BY_ID](this.annotationId);
    },
    typeName() {
      return !isNil(this.annotation.type)
        ? this.annotationType[this.annotation.type].name
        : null;
    },
    subTypes: function() {
      return this.annotation.subTypes.map(
        subTypeId => this.annotationSubType[subTypeId]
      );
    }
  }
};
</script>
