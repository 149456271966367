<template>
  <v-autocomplete
    v-model="internalValue"
    slot-scope="{ data, isLoading }"
    v-validate="dataVvRules"
    :search-input.sync="searchInput"
    :items="items"
    chips
    small-chips
    hide-no-data
    hide-selected
    :loading="isLoading"
    :disabled="disabled"
    :readonly="isReadOnly"
    :multiple="multiple"
    :label="multiple ? 'Articles' : 'Article'"
    placeholder="Start typing to Search"
    prepend-icon="search"
    :item-text="itemText"
    :item-value="itemValue"
    :data-vv-name="dataVvName"
    :error-messages="errorMessages"
  />
</template>

<script type="text/babel">
import { isNil } from "lodash";
import api from "@/api";
const fetchFunction = api.article.search;

export default {
  name: "v-autocomplete-article",
  props: {
    value: {
      type: [Number, Array],
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: "id"
    },
    itemText: {
      type: String,
      default: "name"
    },
    dataVvRules: {
      type: String,
      required: false
    },
    dataVvName: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array,
      required: false
    }
  },
  data: () => {
    return {
      fetchCall: fetchFunction,
      initialLoad: false,
      data: null,
      isLoading: false,
      items: [],
      searchInput: null,
      queryTimeout: null,
      filter: {
        search: null
      },
      dataLoaded: false
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", Object.is(newValue, undefined) ? null : newValue);
      }
    },
    isDisabled() {
      return this.disabled || this.loading;
    },
    isReadOnly() {
      return this.disabled || this.loading;
    }
  },
  watch: {
    searchInput(newValue) {
      clearTimeout(this.queryTimeout);
      if (!isNil(newValue) && newValue.length > 0) {
        this.queryTimeout = setTimeout(() => {
          this.getDataFromApi();
        }, 500);
      }
    }
  },
  created() {
    if (this.initialLoad) {
      this.getDataFromApi();
    }
    if (Array.isArray(this.internalValue)) {
      if (this.internalValue.length > 0) {
        this.getDataFromApiBasedOnId();
      }
    } else {
      if (!isNil(this.internalValue)) {
        this.getDataFromApiBasedOnId();
      }
    }
  },
  methods: {
    async getDataFromApi() {
      clearTimeout(this.queryTimeout);
      this.filter.ids = null;
      this.filter.search = this.searchInput;
      this.isLoading = true;
      this.data = await this.fetchCall({
        filters: this.filter
      });
      this.items = this.items
        .filter(item => this.internalValue.includes(item.id))
        .concat(this.data);
      this.isLoading = false;
    },
    async getDataFromApiBasedOnId() {
      clearTimeout(this.queryTimeout);
      this.filter.ids = this.internalValue;
      this.isLoading = true;
      this.data = await this.fetchCall({
        filters: this.filter,
        includes: "website"
      });
      this.items = this.items
        .filter(item => this.internalValue.includes(item.id))
        .concat(this.data);
      this.$emit("set-articles", this.items);
      this.isLoading = false;
    }
  }
};
</script>
